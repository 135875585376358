/* eslint-disable no-empty */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import company from './modules/company.module';
import common from './modules/common.module';
import auth from './modules/auth.module';
import carWizard from './modules/carWizard.module';
import garage from './modules/garage.module';
import reminder from './modules/reminder.module';
import snackBar from './modules/snackBar.module';
import messageDialog from './modules/messageDialog.module';
import account from './modules/account.module';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const authPersistedStateConfigs = {
  key: 'lead-gen-auth',
  paths: ['auth'],
  reducer(state) {
    if (state.auth.user === null)
      return {
        auth: {
          smsResponse: state.auth.smsResponse,
          mobilePhone: state.auth.mobilePhone,
        },
      };
    return { auth: state.auth };
  },
  storage: {
    getItem: (key) => {
      let storeData = '';
      try {
        storeData = ls.get(key);
      } catch (ex) {}

      return storeData;
    },
    setItem: (key, value) => {
      const data = JSON.parse(value);
      if (data.auth) ls.set(key, value);
    },
    removeItem: (key) => ls.remove(key),
  },
};

const companyPersistedStateConfigs = {
  key: 'company',
  paths: ['company'],
  storage: window.sessionStorage,
};

const carWizardPersistedStateConfigs = {
  key: 'carWizard',
  paths: ['carWizard'],
  storage: window.sessionStorage,
};

export default new Vuex.Store({
  modules: {
    company,
    common,
    auth,
    carWizard,
    garage,
    reminder,
    snackBar,
    messageDialog,
    account,
  },
  plugins: [
    createPersistedState(authPersistedStateConfigs),
    createPersistedState(companyPersistedStateConfigs),
    createPersistedState(carWizardPersistedStateConfigs),
  ],
});
