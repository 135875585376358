const IN_BROWSER = typeof window !== 'undefined';
const IS_DEBUG = process.env.DEBUG === 'true';
const IS_PROD = process.env.NODE_ENV === 'production';
const IS_SERVER = process.env.VUE_APP_WEBPACK_TARGET === 'node';
const BUCKET_URL = process.env.VUE_APP_STATIC_BUCKET;
const ASSET_PATH = `${BUCKET_URL}assets/`;

module.exports = {
  IN_BROWSER,
  IS_DEBUG,
  IS_PROD,
  IS_SERVER,
  BUCKET_URL,
  ASSET_PATH,
};
