<template>
  <v-app>
    <SnackBarMessage />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_COMPANY } from './store/modules/company.module';
export default {
  name: 'App',
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['company']),
  },
  methods: {
    ...mapActions({
      fetchCompany: FETCH_COMPANY,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      if (this.company === null) this.fetchCompany();
    });
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@100;200;300;400;500;600&display=swap');
* {
  font-family: 'Encode Sans Semi Condensed', sans-serif;
}

a.clear-link {
  text-decoration: none !important;
  color: #000 !important;
}

.v-application {
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6,
  .text-subtitle-1,
  .text-subtitle-2,
  .text-body-1,
  .text-body-2 {
    font-family: 'Encode Sans Semi Condensed', sans-serif !important;
  }
}

.v-subheader {
  color: #333 !important;
  padding: 0px;
  font-weight: 500;
}

body {
  .v-application {
    background-color: #ebebeb !important;
  }
}
</style>
