import Vue from 'vue';
import Vuetify from 'vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/dist/vuetify.min.css';
import minifyTheme from 'minify-css-string';
import { tr, en } from 'vuetify/lib/locale';

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: {
      en,
      tr,
    },
    current: 'tr',
  },
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {},
      light: {},
    },
    options: {
      minifyTheme,
      customProperties: true,
    },
  },
};

export default new Vuetify(opts);
