import Vue from 'vue';

export const SET_MESSAGE_DIALOG = 'setMessageDialog';
export const DISPOSE_MESSAGE = 'disposeMessage';

const getDefaultState = () => ({
  messageDialogOptions: {
    dialog: false,
    persistent: false,
    messageType: null,
    title: null,
    subTitle: null,
    text: null,
    defaultCloseButton: true,
    closeButtonText: null,
    buttons: [],
  },
});

const state = getDefaultState();

const getters = {
  getMessageDialogOptions: (state) => state.messageDialogOptions,
};

const actions = {
  [SET_MESSAGE_DIALOG](context, payload) {
    context.commit(SET_MESSAGE_DIALOG, payload);
  },
  [DISPOSE_MESSAGE](context) {
    return new Promise((resolve) => {
      context.commit(DISPOSE_MESSAGE);
      resolve();
    });
  },
};

const mutations = {
  [SET_MESSAGE_DIALOG](state, options) {
    const defaultopts = state.messageDialogOptions;
    const opts = Vue.prototype.$cloneDeep({
      ...defaultopts,
      ...options,
      dialog: true,
    });
    state.messageDialogOptions = opts;
  },
  [DISPOSE_MESSAGE](state) {
    const newState = { ...getDefaultState() };
    Object.assign(state, newState);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
