export const wizardStep = {
  // MODEL: 1,
  CAR_INFO: 2,
  DAMAGE: 3,
  CONTACT: 4,
  SMS_APPROVE: 5,
  OFFER: 6,
  COMPLETE: 7,
};

export const carPartType = {
  LEFT_FRONT_FENDER: {
    id: 1,
    label: 'Sol Ön Çamurluk',
    field: 'leftFrontFenderDamage',
  },
  RIGHT_FRONT_FENDER: {
    id: 2,
    label: 'Sağ Ön Çamurluk',
    field: 'rightFrontFenderDamage',
  },
  LEFT_FRONT_DOOR: {
    id: 3,
    label: 'Sol Ön Kapı',
    field: 'leftFrontDoorDamage',
  },
  RIGHT_FRONT_DOOR: {
    id: 4,
    label: 'Sağ Ön Kapı',
    field: 'rightFrontDoorDamage',
  },
  LEFT_REAR_DOOR: {
    id: 5,
    label: 'Sol Arka Kapı',
    field: 'leftRearDoorDamage',
  },
  RIGHT_REAR_DOOR: {
    id: 6,
    label: 'Sağ Arka Kapı',
    field: 'rightRearDoorDamage',
  },
  LEFT_REAR_FENDER: {
    id: 7,
    label: 'Sol Arka Çamurluk',
    field: 'leftRearFenderDamage',
  },
  RIGHT_REAR_FENDER: {
    id: 8,
    label: 'Sağ Arka Çamurluk',
    field: 'rightRearFenderDamage',
  },
  FRONT_HOOD: { id: 9, label: 'Motor Kaputu', field: 'frontHoodDamage' },
  REAR_HOOD: { id: 10, label: 'Bagaj Kaputu', field: 'rearHoodDamage' },
  CEILING: { id: 11, label: 'Tavan', field: 'ceilingDamage' },
};

export const issueType = {
  NONE: {
    id: 0,
    label: 'Temiz',
    color: 'default',
    code: '',
    onlyListing: false,
  },
  DAMAGED: {
    id: 4,
    label: 'Hasarlı',
    color: 'grey',
    code: 'h',
    onlyListing: true,
  },
  SCRATCHED: {
    id: 1,
    label: 'Çizik/Göçük',
    color: 'green',
    code: 'ç',
    onlyListing: false,
  },
  LOCALLY_PAINTED: {
    id: 5,
    label: 'Lokal Boyalı',
    color: 'orange',
    code: 'lb',
    onlyListing: true,
  },
  PAINTED: {
    id: 2,
    label: 'Boyali',
    color: 'indigo',
    code: 'b',
    onlyListing: false,
  },
  REPLACED: {
    id: 3,
    label: 'Değişen',
    color: 'red',
    code: 'd',
    onlyListing: false,
  },
};

export const sellStep = {
  PRICE_CALCULATED: 40,
  NEW_REQUEST: 50,
  PRE_EXAMINATION: 60,
  SCHEDULED: 70,
  EXPERTISE: 80,
  PAYMENT: 90,
  COMPLETED: 100,
  CANCELLED: 110,
};

export const fileType = {
  LICENSE: 1,
  EXPERTISE: 2,
  IMAGE: 3,
};

export const roleType = {
  SUPER_ADMIN: 1,
  COMPANY_ADMIN: 2,
  COMPANY_USER: 3,
  END_USER: 4,
};

export const listingDataSortType = {
  LAST_ADDED: { id: 0, label: 'Son Eklenen' },
  LOWER_PRICE: { id: 1, label: 'Fiyata Göre (Önce En Düşük)' },
  HIGHER_PRICE: { id: 2, label: 'Fiyata Göre (Önce En Yüksek)' },
  LOWER_KILOMETER: { id: 3, label: 'KM Göre (Önce En Düşük)' },
  HIGHER_KILOMETER: { id: 4, label: 'KM Göre (Önce En Yüksek)' },
  OLDEST_YEAR: { id: 5, label: 'Modele Göre (Önce En Eski)' },
  NEWEST_YEAR: { id: 6, label: 'Modele Göre (Önce En Yeni)' },
};

export const listingFilterType = {
  NONE: { id: 0, field: '' },
  CATEGORY: { id: 1, field: 'categories' },
  MODEL: { id: 2, field: 'models' },
  BODY: { id: 3, field: 'bodyTypes' },
  TRANSMISSION: { id: 4, field: 'transmissionTypes' },
  FUEL: { id: 5, field: 'fuelTypes' },
  TRACTION: { id: 6, field: 'tractionTypes' },
  VERSION: { id: 7, field: 'versions' },
  COLOR: { id: 8, field: 'colors' },
  YEAR: { id: 9, field: 'year' },
  KILOMETER: { id: 10, field: 'kilometer' },
  PRICE: { id: 11, field: 'price' },
};

export default {
  wizardStep,
  carPartType,
  issueType,
  sellStep,
  fileType,
  listingDataSortType,
};
