import VueCurrencyFilter from 'vue-currency-filter';
import VueMoment from 'vue-moment';

const GlobalFilters = {
  install(Vue) {
    Vue.use(VueMoment);
    Vue.use(VueCurrencyFilter, [
      {
        // default name 'currency'
        symbol: '€',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: true,
        avoidEmptyDecimals: '',
      },
      {
        name: 'euro',
        symbol: '€',
        thousandsSeparator: ',',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: true,
        avoidEmptyDecimals: '',
      },
      {
        name: 'dollar',
        symbol: '$',
        thousandsSeparator: ' ',
        fractionCount: 2,
        fractionSeparator: '.',
        symbolPosition: 'front',
        symbolSpacing: true,
        avoidEmptyDecimals: '',
      },
      {
        name: 'tl',
        symbol: '₺',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true,
        avoidEmptyDecimals: '',
      },
    ]);
  },
};

export default GlobalFilters;
