import ApiService from '@/common/api.service';

export const FETCH_COMPANY = 'fetchCompany';

const endPoints = {
  getCmpInfo: 'company/getcmpinfo',
};

const getDefaultState = () => ({
  company: null,
});

const state = getDefaultState();

const getters = {
  company: (state) => state.company,
};

const actions = {
  [FETCH_COMPANY](context) {
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getCmpInfo)
        .then((response) => {
          context.commit(FETCH_COMPANY, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const mutations = {
  [FETCH_COMPANY](state, response) {
    state.company = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
