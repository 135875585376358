// eslint-disable-next-line no-unused-vars
import ApiService from '@/common/api.service';

export const SET_LOADING = 'setLoadingReminder';
export const FETCH_REMINDERS = 'fetchReminders';
export const ADD_REMINDER = 'addReminder';
export const DELETE_REMINDER = 'deleteReminder';

const endPoints = {
  getReminders: 'garagereminder/getreminders',
  save: 'garagereminder/save',
  delete: 'garagereminder/delete',
};

const state = {
  loading: false,
  reminders: [],
  garageReminderLimit: 0,
};

const getters = {
  isReminderLoading: (state) => state.loading,
  reminders: (state) => state.reminders.sort((a, b) => a.id - b.id),
  reminderCount: (state) => state.reminders.length,
  hasAddReminderLimit: (state) => state.garageReminderLimit - state.reminders.length,
};

const actions = {
  [FETCH_REMINDERS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getReminders)
        .then((response) => {
          context.commit(FETCH_REMINDERS, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ADD_REMINDER](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.save, payload)
        .then((response) => {
          // context.commit(ADD_REMINDER, { ...payload, id: response });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_REMINDER](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.delete, payload.id)
        .then((response) => {
          context.commit(DELETE_REMINDER, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [FETCH_REMINDERS](state, response) {
    state.reminders = response.reminders;
    state.garageReminderLimit = response.garageReminderLimit;
  },
  [ADD_REMINDER](state, reminder) {
    state.reminders.push(reminder);
  },
  [DELETE_REMINDER](state, reminder) {
    const index = state.reminders.indexOf(reminder);
    state.reminders.splice(index, 1);
  },
  [SET_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
