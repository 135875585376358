/* eslint-disable no-unused-vars */
import Vue from 'vue';
import store from '@/store/index';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (store.getters?.isAuthenticated) {
    next();
    return;
  }

  router.push({
    name: 'login',
    params: {
      returnTo: to.path,
      query: to.query,
    },
  });
};

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters?.isAuthenticated || to.name === 'logout') {
    next();
    return;
  }

  router.push({
    name: 'login',
    params: {
      returnTo: to.path,
      query: to.query,
    },
  });
};

const routes = [
  {
    component: () => import('@/views/_base/AuthBase.vue'),
    path: '/auth',
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/auth/register/Index.vue'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/login/Index.vue'),
      },
      {
        path: '/forget',
        name: 'forget',
        redirect: '/forget',
        component: () => import('@/views/auth/forget/_Layout.vue'),
        children: [
          {
            path: '',
            name: 'forgetReset',
            component: () => import('@/views/auth/forget/reset/Index.vue'),
          },
          {
            path: '/approve',
            name: 'forgetApprove',
            component: () => import('@/views/auth/forget/approve/Index.vue'),
          },
        ],
      },
      {
        path: '/approve',
        name: 'approve',
        component: () => import('@/views/auth/approve/Index.vue'),
      },
      {
        path: '/login?logout',
        name: 'logout',
        component: () => import('@/views/auth/login/Index.vue'),
      },
    ],
  },
  {
    path: '/tour',
    name: 'tour',
    component: () => import('@/views/tour/Index.vue'),
    meta: {
      group: 'tour',
    },
  },
  {
    redirect: '/tour',
    path: '/',
    component: () => import('@/views/_base/EndUserBase.vue'),
    // beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/Index.vue'),
      },
      {
        path: '/reminder',
        name: 'reminder',
        redirect: '/reminder',
        beforeEnter: ifAuthenticated,
        component: () => import('@/views/reminder/_Layout.vue'),
        children: [
          {
            path: '/',
            name: 'reminderList',
            component: () => import('@/views/reminder/list/Index.vue'),
          },
        ],
      },
      {
        path: '/wizard',
        name: 'wizard',
        redirect: '/wizard/info',
        component: () => import('@/views/wizard/_Layout.vue'),
        children: [
          {
            path: 'info',
            name: 'wizardInfo',
            component: () => import('@/views/wizard/info/Index.vue'),
            meta: {
              group: 'wizard',
            },
          },
          {
            path: 'damage',
            name: 'wizardDamage',
            component: () => import('@/views/wizard/damage/Index.vue'),
            meta: {
              group: 'wizard',
            },
          },
          {
            path: 'contact',
            name: 'wizardContact',
            component: () => import('@/views/wizard/contact/Index.vue'),
            meta: {
              group: 'wizard',
            },
          },
          {
            path: 'offer',
            name: 'wizardOffer',
            component: () => import('@/views/wizard/offer/Index.vue'),
            meta: {
              group: 'wizard',
            },
          },
          {
            path: 'complete',
            name: 'wizardComplete',
            component: () => import('@/views/wizard/complete/Index.vue'),
            meta: {
              group: 'wizard',
            },
          },
        ],
      },
      {
        path: '/garage',
        name: 'garage',
        redirect: '/garage/my-cars',
        component: () => import('@/views/garage/_Layout.vue'),
        children: [
          {
            path: 'my-cars',
            name: 'listCar',
            component: () => import('@/views/garage/list/Index.vue'),
            beforeEnter: ifAuthenticated,
          },
          {
            path: 'car/:id?',
            name: 'car',
            component: () => import('@/views/garage/car/Index.vue'),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.group === 'tour' && store.getters?.isAuthenticated) {
    next('/garage');
  }

  next();
});

export default router;
