import Vue from 'vue';
import NProgress from 'vue-nprogress';

Vue.use(NProgress);

const opts = {
  showSpinner: false,
  easing: 'ease',
  speed: 500,
};

export default new NProgress(opts);
