import HttpService from '@/common/axios.http.service';
import { SHOW_MESSAGE } from '@/store/modules/snackBar.module';

const ApiService = {
  init(page) {
    this.page = page;
    HttpService.errorHandler = this.errorHandler;
    HttpService.customHeaders = this.setCustomHeaders;
    HttpService.init(page);
  },

  setCustomHeaders() {
    return [
      { key: 'clisrc', value: process.env.VUE_APP_TITLE },
      { key: 'cliver', value: process.env.VUE_APP_VERSION },
    ];
  },

  errorHandler(result) {
    let err;
    if (result.response.data) {
      err = result.response.data;

      if (err.responseCode === 600 || err.responseCode === 601) {
        this.$store.dispatch(SHOW_MESSAGE, {
          message: err.responseText,
          icon: 'fas fa-times',
        });
        this.$store.dispatch('logout');
      } else if (err.responseCode === 6003) {
        this.$store.dispatch('logout');

        if (err.httpStatus === 401) {
          window.location.href = '/login';
        }
      } else {
        this.$store.dispatch(SHOW_MESSAGE, {
          message: err.responseText,
          icon: 'fas fa-times',
        });
      }
    } else {
      err = { responseText: result.message };
      this.$store.dispatch(SHOW_MESSAGE, {
        message: err.responseText,
        icon: 'fas fa-times',
      });
    }

    return Promise.reject(err);
  },

  post(url, payload, config) {
    return new Promise((resolve, reject) => {
      HttpService.post(url, payload, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ApiService;
