<template>
  <v-dialog v-model="getMessageDialogOptions.dialog" max-width="350" :persistent="getMessageDialogOptions.persistent">
    <v-card>
      <v-card-title class="text-h5" v-if="getMessageDialogOptions.title">
        {{ getMessageDialogOptions.title }}
      </v-card-title>
      <v-card-text class="text-center pa-10">
        <v-icon v-text="messageIcon.text" :color="messageIcon.color" size="70"></v-icon>
        <v-spacer class="my-5"></v-spacer>
        <div class="text-h5 font-weight-bold" v-if="getMessageDialogOptions.subTitle">
          {{ getMessageDialogOptions.subTitle }}
        </div>
        <v-spacer class="my-3"></v-spacer>
        <div v-html="getMessageDialogOptions.text"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-for="(button, index) in dialogButtons"
          :key="`message-dialog-btn-${index}`"
          :color="button.color"
          text
          @click="button.action"
        >
          {{ button.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';

export default {
  name: 'MessageDialog',
  data() {
    return {
      defaultCloseButton: {
        text: 'Tamam',
        color: 'red',
      },
    };
  },
  computed: {
    ...mapGetters(['getMessageDialogOptions']),
    closeButton() {
      const btn = {
        ...this.$cloneDeep(this.defaultCloseButton),
        action: () => this.disposeMessage(),
      };

      return btn;
    },
    messageIcon() {
      let icon = null;
      if (!this.getMessageDialogOptions.messageType) return '';
      switch (this.getMessageDialogOptions.messageType) {
        case 'info':
          icon = { text: 'fas fa-info-circle', color: 'teal' };
          break;
        case 'warning':
          icon = { text: 'fas fa-exclamation-circle', color: 'orange' };
          break;
        case 'error':
          icon = { text: 'fas fa-times', color: 'red' };
          break;
        default:
          break;
      }
      return icon;
    },
    dialogButtons() {
      if (this.getMessageDialogOptions.buttons != null) {
        if (this.getMessageDialogOptions.buttons.length > 0) {
          const buttons = [];
          if (this.getMessageDialogOptions.defaultCloseButton) {
            const { closeButton } = this;

            if (this.getMessageDialogOptions.closeButtonText)
              closeButton.text = this.getMessageDialogOptions.closeButtonText;
            buttons.push(closeButton);
          }

          buttons.push(...this.getMessageDialogOptions.buttons);
          return buttons;
        }

        return [this.closeButton];
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      disposeMessage: DISPOSE_MESSAGE,
    }),
  },
  watch: {
    'getMessageDialogOptions.dialog': {
      handler(show) {
        if (!show) {
          this.defaultCloseButton = {
            text: 'Tamam',
            color: 'red',
          };
        }
      },
    },
  },
};
</script>

<style></style>
