// eslint-disable-next-line no-unused-vars
import ApiService from '@/common/api.service';

export const FETCH_GARAGE = 'fetchGarage';
export const FETCH_PRESET = 'fetchPreset';
export const SET_FORM = 'setForm';
export const ADD_CAR = 'addCar';
export const DELETE_CAR = 'deleteCar';
export const CALCULATE_GARAGE_PRICE = 'calculateGaragePrice';
export const SET_LOADING = 'setLoading';
export const SET_LOADING_KEY = 'setLoadingKey';
export const SAVE_FROM_WIZARD = 'saveFromWizard';
export const FETCH_CAR_INSIDE = 'fetchCarInside';
export const FETCH_CAR_TECH_PROPS = 'fetchCarTechProps';
export const FETCH_PRICE_OFFERS = 'fetchPriceOffers';
export const COLLECT_PRICE_OFFERS = 'collectPriceOffers';
export const APPLY_OFFER = 'applyOffer';

const endPoints = {
  getPreset: 'garage/getpreset',
  getMyGarage: 'garage/getmygarage',
  save: 'garage/save',
  deactivate: 'garage/deactivate',
  delete: 'garage/delete',
  calculateGaragePrice: 'garage/calculateprice',
  saveFromWizard: 'garage/savefromwizard',
  getCarInsight: 'garage/getcarinsight',
  getCarTechProperties: 'garage/getcartechproperties',
  getPriceOffers: 'garage/getpriceoffers',
  collectPriceOffers: 'garage/collectpriceoffers',
  applyOffer: 'garage/applyforoffer',
};

const state = {
  loading: false,
  loadingKey: null,
  garage: [],
  carTechProps: [],
  allowCarInsert: false,
  allowDreamCarInsert: false,
  form: null,
  preset: {
    car: null,
    years: [],
    colors: [],
  },
};

const getters = {
  isLoading: (state) => state.loading,
  loadingKey: (state) => state.loadingKey,
  garage: (state) => state.garage,
  allowCarInsert: (state) => state.allowCarInsert,
  allowDreamCarInsert: (state) => state.allowDreamCarInsert,
  hasAddLimit: (state) => state.allowCarInsert || state.allowDreamCarInsert,
  garageCount: (state) => state.garage.length,
  dreamsCount: (state) => state.garage.filter((x) => x.isDreamCar).length,
  carsCount: (state) => state.garage.filter((x) => !x.isDreamCar).length,
  totalValues: (state) => state.garage.filter((x) => !x.isDreamCar).reduce((pre, curr) => pre + curr.price, 0),
  dreamValues: (state) => state.garage.filter((x) => x.isDreamCar).reduce((pre, curr) => pre + curr.price, 0),
  car: (state) => state.preset.car,
  years: (state) => state.preset.years.sort((a, b) => b - a),
  colors: (state) => state.preset.colors.map((x) => ({ id: x.id, value: x.name })),
  form: (state) => state.form,
};

const actions = {
  [FETCH_CAR_TECH_PROPS](context, payload) {
    // context.commit(FETCH_CAR_TECH_PROPS, []);
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getCarTechProperties, payload.key)
        .then((response) => {
          context.commit(FETCH_CAR_TECH_PROPS, { response, carId: payload.key });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [FETCH_PRICE_OFFERS](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getPriceOffers, payload.key)
        .then((response) => {
          context.commit(FETCH_PRICE_OFFERS, { response, carId: payload.key });
          setTimeout(() => {
            resolve(response);
          }, 5000);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [COLLECT_PRICE_OFFERS](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.collectPriceOffers, payload.key)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [APPLY_OFFER](context, payload) {
    context.commit(SET_LOADING_KEY, 'apply-offer');
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.applyOffer, payload.id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING_KEY, null);
        });
    });
  },
  [FETCH_CAR_INSIDE](context, payload) {
    // context.commit(FETCH_CAR_INSIDE, null);
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getCarInsight, payload.key)
        .then((response) => {
          context.commit(FETCH_CAR_INSIDE, { response, carId: payload.key });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [SAVE_FROM_WIZARD](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.saveFromWizard, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [FETCH_GARAGE](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getMyGarage)
        .then((response) => {
          context.commit(FETCH_GARAGE, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FETCH_PRESET](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getPreset, payload)
        .then((response) => {
          context.commit(FETCH_PRESET, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [ADD_CAR](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      context.commit(SET_FORM, payload.data);
      if (payload.save) {
        ApiService.post(endPoints.save, context.getters.form)
          .then((response) => {
            context.commit(ADD_CAR, response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            context.commit(SET_LOADING, false);
          });
      } else {
        context.commit(SET_LOADING, false);
        resolve();
      }
    });
  },
  [DELETE_CAR](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.delete, payload.key)
        .then((response) => {
          context.commit(DELETE_CAR, {
            car: payload,
            allow: { allowCarInsert: response.allowCarInsert, allowDreamCarInsert: response.allowDreamCarInsert },
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [CALCULATE_GARAGE_PRICE](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      const { car, date } = payload;
      ApiService.post(endPoints.calculateGaragePrice, car.key)
        .then((response) => {
          context.commit(CALCULATE_GARAGE_PRICE, { key: car.key, response, date });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [FETCH_CAR_TECH_PROPS](state, { response, carId }) {
    const car = state.garage.find((x) => x.key == carId);
    if (car) {
      car.carTechProps = response;
    }
  },
  [FETCH_PRICE_OFFERS](state, { response, carId }) {
    const car = state.garage.find((x) => x.key == carId);
    if (car) {
      car.priceOfferResult = response;
    }
  },
  [FETCH_CAR_INSIDE](state, { response, carId }) {
    const car = state.garage.find((x) => x.key == carId);
    if (car) {
      car.carInside = response;
      car.priceTrend = {
        dates: response.priceTrendDates,
        prices: response.priceTrendPrices,
      };
    }
  },
  [FETCH_GARAGE](state, response) {
    state.garage = response.cars.map((x) => ({
      ...x,
      carInside: null,
      priceTrend: null,
      priceOfferResult: null,
      carTechProps: null,
    }));
    state.allowCarInsert = response.allowCarInsert;
    state.allowDreamCarInsert = response.allowDreamCarInsert;
  },
  [FETCH_PRESET](state, response) {
    state.preset.years = response.years;
    state.preset.colors = response.carColor;
    state.preset.car = response.garage;
    state.allowCarInsert = response.allowCarInsert;
    state.allowDreamCarInsert = response.allowDreamCarInsert;
  },
  [SET_FORM](state, data) {
    state.form = { ...state.form, ...data };
  },
  [ADD_CAR](state, response) {
    state.garage.push(response);
  },
  [DELETE_CAR](state, { car, allow }) {
    const index = state.garage.indexOf(car);
    state.garage.splice(index, 1);

    state.allowCarInsert = allow.allowCarInsert;
    state.allowDreamCarInsert = allow.allowDreamCarInsert;
  },
  [CALCULATE_GARAGE_PRICE](state, { key, response, date }) {
    const car = state.garage.find((x) => x.key === key);
    if (car) {
      car.price = response;
      car.priceUpdateTime = date;
    }
  },
  [SET_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [SET_LOADING_KEY](state, key) {
    state.loadingKey = key;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
