import ApiService from '@/common/api.service';

// action types
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const FORGET = 'forget';
export const SET_SMS_RESPONSE = 'setSmsResponse';
export const APPROVE = 'approve';
export const RESEND_CODE = 'resendCode';
export const DECREASE_SECOND = 'decreaseSecond';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';

const endPoints = {
  loginSuperApp: 'auth/loginsuperapp',
  register: 'user/register',
  resetPassword: 'user/resetpassword',
  approveSmsCode: 'user/approvesmscode',
  resendSmsValidationCode: 'user/resendsmsvalidationcode',
};

const state = {
  errors: null,
  user: null,

  mobilePhone: null,
  smsResponse: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  currentUser: (state) => state.user,
  token: (state) => state.user?.token ?? null,
  appVersion: () => process.env.VUE_APP_VERSION,
  mobilePhone: (state) => state.mobilePhone,
  smsResponse: (state) => state.smsResponse,
};

const actions = {
  [REGISTER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.register, payload)
        .then((response) => {
          context.commit(SET_SMS_RESPONSE, { response, mobilePhone: payload.mobilePhone });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [LOGIN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.loginSuperApp, { phone: payload.userName, password: payload.userPassword })
        .then((data) => {
          if (!data) return;
          context.commit(SET_AUTH, data);
          resolve();
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.responseText);
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve) => {
      context.commit(PURGE_AUTH);
      resolve();
    });
  },
  [FORGET](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.resetPassword, payload)
        .then((response) => {
          context.commit(SET_SMS_RESPONSE, { response, mobilePhone: payload });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [APPROVE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.approveSmsCode, payload)
        .then((response) => {
          context.commit(SET_AUTH, response);
          context.commit(APPROVE);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RESEND_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.resendSmsValidationCode, payload)
        .then((response) => {
          context.commit(SET_SMS_RESPONSE, { response, mobilePhone: payload });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.user = user;
    state.errors = null;
  },
  [PURGE_AUTH](state) {
    state.user = null;
    state.errors = null;
    window.localStorage.clear();
  },
  [SET_SMS_RESPONSE](state, { response, mobilePhone }) {
    state.smsResponse = {
      ...response,
      timeOutSecond: response.wizardSmsTimeOutMinutes * 60,
      mobilePhone,
    };
    state.mobilePhone = mobilePhone;
  },
  [APPROVE](state) {
    state.smsResponse = null;
    state.mobilePhone = null;
  },
  [DECREASE_SECOND](state) {
    if (state.smsResponse) {
      state.smsResponse.timeOutSecond -= 1;
      if (state.smsResponse.timeOutSecond === 0) {
        state.smsResponse = null;
      }
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
