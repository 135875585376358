import ApiService from '@/common/api.service';

export const FETCH_PROFILE_PRESET = 'fetchProfilePreset';
export const UPDATE_PROFILE = 'updateProfile';
export const SET_LOADING = 'setLoading';

const endPoints = {
  getProfilePreset: 'user/getprofilepreset',
  updateProfile: 'user/updateprofile',
};

const state = {
  profileLoading: false,
  preset: {
    genders: [],
    cities: [],
  },
  profile: null,
};

const getters = {
  profileLoading: (state) => state.profileLoading,
  genders: (state) => state.preset.genders,
  cities: (state) => state.preset.cities,
  profile: (state) => state.profile,
};

const actions = {
  [FETCH_PROFILE_PRESET](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.getProfilePreset)
        .then((response) => {
          context.commit(FETCH_PROFILE_PRESET, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_PROFILE](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(endPoints.updateProfile, payload)
        .then((response) => {
          context.commit(UPDATE_PROFILE, payload);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [FETCH_PROFILE_PRESET](state, response) {
    state.profile = response.userProfile;
    state.preset.genders = response.genderList;
    state.preset.cities = response.cityList;
  },
  [UPDATE_PROFILE](state, response) {
    var new_response = {
      ...response,
      password: null,
      rePassword: null,
    };

    state.profile = new_response;
  },
  [SET_LOADING](state, isLoading) {
    state.profileLoading = isLoading;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
