/* eslint-disable no-unused-vars */
import VuetifyMask from 'vuetify-mask';
import VueMask from 'v-mask';

const GlobalPlugins = {
  install(Vue) {
    Vue.use(VuetifyMask);
    Vue.use(VueMask);
  },
};

export default GlobalPlugins;
