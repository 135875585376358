/* eslint-disable no-param-reassign */
import CloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

import { toTitleCase, sleep, hexToRgb, capitalize } from '@/common/utils';

const GlobalFunctions = {
  install(Vue) {
    Vue.prototype.$cloneDeep = (obj) => CloneDeep(obj);
    Vue.prototype.$debounce = (func, wait, options) => debounce(func, wait, options);
    Vue.prototype.$sleep = sleep;
    Vue.prototype.$toTitleCase = (phrase) => toTitleCase(phrase);
    Vue.prototype.$hexToRgb = (hex) => hexToRgb(hex);
    Vue.prototype.$capitalize = (input) => capitalize(input);
  },
};

export default GlobalFunctions;
