import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
// import VueGtag from 'vue-gtag';
import { IS_PROD, BUCKET_URL, ASSET_PATH } from '@/common/globals';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

import GlobalPlugins from './common/globalPlugins';
import GlobalComponents from './common/globalComponents';
import GlobalFunctions from './common/globalFunctions';
import GlobalFilters from './common/globalFilters';
import ApiService from './common/api.service';

Vue.prototype.$bucketUrl = BUCKET_URL;
Vue.prototype.$assetPath = ASSET_PATH;
Vue.config.productionTip = false;

Vue.use(GlobalPlugins);
Vue.use(GlobalComponents);
Vue.use(GlobalFunctions);
Vue.use(GlobalFilters);

if (IS_PROD) {
  // Vue.use(
  //   VueGtag,
  //   {
  //     bootstrap: false,
  //   },
  //   router,
  // );
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
  beforeCreate() {
    // API service init
    ApiService.init(this);
  },
}).$mount('#app');
