import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import NProgress from '@/plugins/nProgress';
import { API_URL } from '@/common/config';
import { convertToCamelcase } from '@/common/utils';

/**
 * Service to call HTTP request via Axios
 */

const HttpService = {
  errorHandler: null,
  customHeaders: null,
  init(page) {
    this.$store = page.$store;
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.interceptors.request.use(
      (request) => {
        NProgress.start();
        // Edit request config
        const modifiedConfig = request;
        modifiedConfig.data = {
          data: request.data,
        };
        return modifiedConfig;
      },
      (error) => {
        NProgress.done();
        Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      (response) => {
        NProgress.done();
        const { data } = convertToCamelcase(response);
        if (!data.isValid) {
          return Promise.reject(data.responseText);
        }
        return data.data;
      },
      (error) => {
        NProgress.done();
        const result = convertToCamelcase({
          response: { ...error.response },
          message: error.message,
        });
        if (
          // eslint-disable-next-line no-prototype-builtins
          error.config.hasOwnProperty('errorHandle') &&
          error.config.errorHandle === false
        )
          return Promise.reject(result);

        if (this.errorHandler === null) return result;
        return this.errorHandler(result);
      },
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader(tokenParam) {
    const token = tokenParam ?? this.$store.getters?.token;
    if (token) Vue.axios.defaults.headers.common.Authorization = token;
    this.addCustomHeaders();
  },

  addCustomHeaders() {
    const customHeaders = this.customHeaders();
    if (customHeaders) {
      customHeaders.forEach((header) => {
        Vue.axios.defaults.headers.common[header.key] = header.value;
      });
    }
  },

  query(resource, params) {
    this.setHeader();
    try {
      return Vue.axios.get(resource, params);
    } catch (error) {
      throw new Error(`[HL] ApiService ${error}`);
    }
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    this.setHeader();
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[HL] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config, token) {
    this.setHeader(token);
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[HL] ApiService ${error}`);
    });
  },

  // When you will cancel
};

export default HttpService;
